import React from 'react';

import type { TRuleWithTheme } from '@ackee/fela';
import type { IStyle } from 'fela';

import { CaseHeader, Gallery, GalleryLayout, HeroBanner, Team, TextContent } from 'modules/references';
import { sendCaseStudyPageView } from 'utils/analytics';

import Seo from '../../components/SEO';

import { ReferenceCategory } from '../../constants';

import socialPreview from '../../../static/social-previews/omg-konsent.png';

const CASE_ID = 'omg';
const MODULE_NAME = 'omg';
const IMAGE_PATH = `references/case-studies/${MODULE_NAME}`;

const colors = {
    bgLight: '#F2F3EC',
    bgGreen: '#D8E9D2',
    fgDark: '#222B59',
    white: '#FFF',
    black: '#000',
};

const textContentStyles = () => ({
    maxWidth: `640px!important`,
});

const visibleFromMd = (rules?: IStyle) => ({
    ...rules,
    display: 'none',
    tablet: {
        display: 'block',
    },
});
const hiddenFromMd = (rules?: IStyle) => ({
    ...rules,
    tablet: {
        display: 'none',
    },
});

const team = [
    {
        department: 'references.case.team.management',
        names: ['Marek Elznic'],
    },
    {
        department: 'references.case.team.design',
        names: ['Roman Gordienko', 'Markéta Hejná', 'Anna Meshcheryakova'],
    },
    {
        department: 'references.case.team.reactNative',
        names: ['Martin Foldyna', 'Martin Macura'],
    },
    {
        department: 'references.case.team.testing',
        names: ['Tomáš Samseli'],
    },
];

const OMGKonsentCaseStudy = () => (
    <>
        <Seo image={socialPreview} pageId={CASE_ID} handleMetadata={sendCaseStudyPageView} />
        <CaseHeader />
        <HeroBanner
            extend={{ subTitleContainer: () => ({ paddingBottom: '5rem' }) }}
            content={{
                projectName: `case.${CASE_ID}.hero.project`,
                projectTitle: `case.${CASE_ID}.hero.title`,
                projectYear: `case.${CASE_ID}.hero.year`,
                client: `case.${CASE_ID}.hero.client`,
                image: {
                    src: `${IMAGE_PATH}/main.png`,
                    style: {
                        height: '100%',
                    },
                    imgStyle: {
                        top: '0px',
                        objectPosition: 'center',
                    },
                    alt: `case.${CASE_ID}.hero.project`,
                },
            }}
            background={colors.bgLight}
            headerTheme="dark"
            textColor={colors.fgDark}
        />

        <TextContent
            headerTheme="dark"
            textColor={colors.fgDark}
            content={{
                section: `case.${CASE_ID}.section1.name`,
                title: `case.${CASE_ID}.section1.title`,
                text: `case.${CASE_ID}.section1.text`,
                category: ReferenceCategory.DIGITALIZATION,
                outputs: [
                    'references.case.output.design',
                    'references.case.output.mobile',
                    'references.case.output.testing',
                ],
            }}
            background={colors.bgLight}
            extend={{ mainContent: textContentStyles }}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/1.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center center',
                            maxHeight: '920px',
                        },
                    },
                },
            ]}
            background={colors.bgLight}
        />

        <TextContent
            headerTheme="dark"
            textColor={colors.black}
            content={{
                section: `case.${CASE_ID}.section2.name`,
                title: `case.${CASE_ID}.section2.title`,
                text: `case.${CASE_ID}.section2.text`,
            }}
            background={colors.bgLight}
            extend={{ mainContent: textContentStyles }}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/2.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center center',
                            maxHeight: '920px',
                        },
                    },
                },
            ]}
            extend={{
                containerWrapper: visibleFromMd({
                    background: `${colors.bgLight} no-repeat bottom/100% url('/images/case-studies/assets/omg/2_bg.svg')`,
                }),
            }}
        />
        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/2_mobile.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center center',
                            maxHeight: '920px',
                        },
                    },
                },
            ]}
            extend={{
                containerWrapper: hiddenFromMd({
                    backgroundColor: colors.bgLight,
                    backgroundRepeat: 'no-repeat, no-repeat',
                    backgroundPosition: 'bottom, center',
                    backgroundSize: 'auto, 656px',
                    backgroundImage: `url('/images/case-studies/assets/omg/2_bg.svg'), url('/images/case-studies/assets/omg/2_splash.png')`,
                }),
            }}
        />

        <TextContent
            headerTheme="dark"
            textColor={colors.black}
            content={{
                section: `case.${CASE_ID}.section3.name`,
                title: `case.${CASE_ID}.section3.title`,
                text: `case.${CASE_ID}.section3.text`,
            }}
            background={colors.bgGreen}
            extend={{ mainContent: textContentStyles }}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/3.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center center',
                            maxHeight: '920px',
                        },
                    },
                },
            ]}
            background={colors.bgGreen}
        />

        <TextContent
            headerTheme="dark"
            textColor={colors.black}
            content={{
                section: `case.${CASE_ID}.section4.name`,
                title: `case.${CASE_ID}.section4.title`,
                text: `case.${CASE_ID}.section4.text`,
            }}
            background={colors.bgGreen}
            extend={{ mainContent: textContentStyles }}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/4.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center center',
                            maxHeight: '920px',
                        },
                    },
                },
            ]}
            extend={{
                containerWrapper: visibleFromMd({
                    backgroundColor: colors.bgLight,
                    backgroundRepeat: 'no-repeat, no-repeat',
                    backgroundPosition: 'center, top',
                    backgroundSize: 'min(2244px, 133%), 100%',
                    backgroundImage: `url('/images/case-studies/assets/omg/4_splash.png'), url('/images/case-studies/assets/omg/4_bg.svg')`,
                }),
            }}
        />
        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/4_mobile.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center center',
                            maxHeight: '920px',
                        },
                    },
                },
            ]}
            extend={{
                containerWrapper: hiddenFromMd({
                    backgroundColor: colors.bgLight,
                    backgroundRepeat: 'no-repeat, no-repeat',
                    backgroundPosition: '25% center, top',
                    backgroundSize: 'min(982px, 133%), 100%',
                    backgroundImage: `url('/images/case-studies/assets/omg/4_splash.png'), url('/images/case-studies/assets/omg/4_bg.svg')`,
                }),
            }}
        />

        {/* TODO: přidat fotku + texty */}
        {/* <Testimonial
            headerTheme="dark"
            background={colors.bgLight}
            content={{
                quote: `case.${CASE_ID}.testimonial.text`,
                person: {
                    name: `case.${CASE_ID}.testimonial.name`,
                    role: `case.${CASE_ID}.testimonial.position`,
                    image: {
                        src: `${IMAGE_PATH}/img_person_jtbanka.jpeg`,
                    },
                },
            }}
            type="PROFILE_RIGHT"
        /> */}

        {/* @ts-expect-error */}
        <Team headerTheme="dark" content={team} background={colors.bgLight} textColor={colors.black} />
    </>
);

export default OMGKonsentCaseStudy;
